//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
    data() {
        return {
            // mounted: false
            showButtons: true
        };
    },
    props: {
        blok: {
            type: Object,

            default: () => { }
        }
    },
    mounted() {
        let elements = [{ 
            elem: '.title-to-animate',
            percentage: 100
        }]
        window.addEventListener('scroll', this.returnanimElements.bind(this, elements))
        
        setTimeout(() => {
            this.returnanimElements(elements)
        }, 200)

        if (this.$route.path.includes('order')) {
            this.showButtons = false
        }
    },
    unmounted () {
        let elements = [{ 
            elem: '.title-to-animate',
            percentage: 100
        }]
        window.addEventListener('scroll', this.returnanimElements.bind(this, elements))
        setTimeout(() => {
            this.returnanimElements(elements)
        }, 200)
    },
    computed: {
        ...mapState(['location']),
        isLocation() {
            return this.location
        },
        isMobile() {
            if (this.$mq == "sm" || this.$mq == "md") {
                return true
            } else return false
        },
        bgBreakfast() {
            let bg = null;

            if (this.$mq == "sm" || this.$mq == "md") {
                bg = this.blok.breakfast[0].background_mobile.filename;
            } else {
                bg = this.blok.breakfast[0].background.filename;
            }

            return bg;
        },

        bgLunch() {
            let bg = null;

            if (this.$mq == "sm" || this.$mq == "md") {
                bg = this.blok.lunch[0].background_mobile.filename;
            } else {
                bg = this.blok.lunch[0].background.filename;
            }

            return bg;
        },

        bgDinner() {
            let bg = null;

            if (this.$mq == "sm" || this.$mq == "md") {
                bg = this.blok.dinner[0].background_mobile.filename;
            } else {
                bg = this.blok.dinner[0].background.filename;
            }

            return bg;
        },

        bgLatenight() {
            let bg = null;

            if (this.$mq == "sm" || this.$mq == "md") {
                bg = this.blok.latenight[0].background_mobile.filename;
            } else {
                bg = this.blok.latenight[0].background.filename;
            }

            return bg;
        },

        daypart() {
            let daypart = null;

            let hours = new Date().getHours();

            let mins = new Date().getMinutes();

            if (hours >= 5 && hours < 10) {
                daypart = "breakfast";
            } else if (hours == 10 && mins < 30) {
                daypart = "breakfast";
            } else if (hours >= 10 && hours < 15) {
                daypart = "lunch";
            } else if (hours == 15 && mins < 30) {
                daypart = "lunch";
            } else if (hours >= 15 && hours < 21) {
                daypart = "dinner";
            } else if (hours >= 21 || hours < 5) {
                daypart = "latenight";
            }
            return daypart;
        }
    },
    methods: {
        isElementXPercentInViewport(el, percentVisible) {
            let rect = el.getBoundingClientRect(),
                windowHeight =
                    window.innerHeight || document.documentElement.clientHeight;

            return !(
                Math.floor(
                    100 - ((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100
                ) < percentVisible ||
                Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) <
                percentVisible
            );
        },
        returnanimElements(elemntsAnim) {
            if (elemntsAnim.length > 0) {
                elemntsAnim.forEach((item) => {
                    var el = document.querySelectorAll(item.elem);
                    
                    if (el.length > 0) {
                        // window.addEventListener('load', (e) => {
                        el.forEach((element) => {
                            if (this.isElementXPercentInViewport(element, item.percentage)) {
                                element.classList.add("in_view");
                            } else {
                                element.classList.remove("in_view");
                            }
                        });
                    }
                });
            }
        },
        animElements(elemntsAnim) {
            
            if (elemntsAnim.length > 0) {
                elemntsAnim.forEach((item) => {
                    var el = document.querySelectorAll(item.elem);
                    
                    if (el.length > 0) {
                        // window.addEventListener('load', (e) => {
                        el.forEach((element) => {
                            if (this.isElementXPercentInViewport(element, item.percentage)) {
                                element.classList.add("in_view");
                            } else {
                                element.classList.remove("in_view");
                            }
                        });
                        // });
                        window.addEventListener("scroll", (e) => {
                            el.forEach((element) => {
                                if (
                                    this.isElementXPercentInViewport(
                                        element,
                                        item.percentage
                                    )
                                ) {
                                    element.classList.add("in_view");
                                } else {
                                    if (item.infinite) {
                                        element.classList.remove("in_view");
                                    }
                                }
                            });
                        });
                    }
                });
            }
        }
    }
};
